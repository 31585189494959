<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="fireFighting"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="소화설비명"
                  name="fireFightingName"
                  v-model="fireFighting.fireFightingName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="관리번호"
                  name="manageNo"
                  v-model="fireFighting.manageNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  :disabled="isOld"
                  type="edit"
                  name="plantCd"
                  v-model="fireFighting.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-select
                  :editable="editable"
                  codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="sopFireFightingTypeCd"
                  label="설비유형"
                  v-model="fireFighting.sopFireFightingTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-select
                  :editable="editable"
                  :comboItems="kindItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="sopFireFightingKindCd"
                  label="설비종류"
                  v-model="fireFighting.sopFireFightingKindCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="editable"
                  label="수량"
                  type="number"
                  name="amount"
                  v-model="fireFighting.amount">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-dept-multi 
                  :editable="editable"
                  :parentCheckDepts="fireFighting.deptCd" 
                  :plantCd="fireFighting.plantCd" 
                  label="관리부서" 
                  name="deptCd" 
                  v-model="fireFighting.deptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="multiple"
                  name="processCd"
                  v-model="fireFighting.processCd">
                </c-process>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="설치관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="설치위치"
                  name="location"
                  v-model="fireFighting.location">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                  :editable="editable"
                  label="설치일"
                  name="installDate"
                  v-model="fireFighting.installDate"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="설치상태 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-6">
                <c-select
                  :editable="editable"
                  :comboItems="disposeFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="disposeFlag"
                  label="폐기여부"
                  v-model="fireFighting.disposeFlag"
                ></c-select>
              </div>
              <div class="col-6">
                <c-datepicker
                  :required="fireFighting.disposeFlag==='Y'"
                  :editable="editable"
                  :disabled="fireFighting.disposeFlag==='N'"
                  label="폐기일"
                  name="disposeDate"
                  v-model="fireFighting.disposeDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',
      }),
    },
    fireFighting: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',  // 소방설비 일련번호
        plantCd: null,  // 사업장
        plantName: null,  // 사업장
        sopFireFightingTypeCd: null,  // 유형
        sopFireFightingTypeName: null,  // 유형
        sopFireFightingKindCd: null,  // 점검 소방시설 종류
        sopFireFightingKindName: '',  // 점검 소방시설 종류
        manageNo: '',  // 관리번호
        fireFightingName: '',  // 소방설비명
        deptCd: '',  // 관리부서
        processCd: '',  // 관련공정
        amount: '',  // 수량
        location: '',  // 설비위치
        installDate: '',  // 설치일
        disposeFlag: 'N',  // 폐기여부
        disposeDate: '',  // 폐기일
        deleteFlag: 'N',  // 삭제여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        histroy: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disposeFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      kindItems: [],
      editable: true,
      isSave: false,
      saveUrl: '',
      checkUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'fireFighting.sopFireFightingTypeCd'() {
      this.setKind();
    }
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.checkUrl = selectConfig.sop.fft.fireFighting.check.url
      this.saveUrl = transactionConfig.sop.fft.fireFighting.update.url
      // code setting
      // list setting
      this.setKind();
    },
    setKind() {
      if (this.fireFighting.sopFireFightingTypeCd) {
        this.$comm.getComboItems('SOP_FIRE_FIGHTING_KIND_CD', this.fireFighting.sopFireFightingTypeCd).then(_result => {
          this.kindItems = _result;
          if (this.$_.findIndex(this.kindItems, { code: this.fireFighting.sopFireFightingKindCd }) === -1) {
            this.$set(this.fireFighting, 'sopFireFightingKindCd', null)
          }
        });
      } else {
        this.kindItems = [];
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.fft.fireFighting.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.fft.fireFighting.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.checkUrl
          this.$http.type = 'GET';
          this.$http.param = {
            sopFireFightingId: this.popupParam.sopFireFightingId,
            manageNo: this.fireFighting.manageNo,
          }
          this.$http.request((_result) => {
            if (!_result.data) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.fireFighting.regUserId = this.$store.getters.user.userId;
                  this.fireFighting.chgUserId = this.$store.getters.user.userId;

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: '동일한 관리번호가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopFireFightingId', result.data)
      this.$emit('getDetail');
    },
  }
};
</script>